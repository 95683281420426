<template>
  <div class="carteamCard">
    <div class="hint-img" v-if="!showOpenCard">
      <span class="iconfont iconchedui"></span>
      <p v-if="showBtn">暂无车队卡，前去开通</p>
      <template v-else>
        <p v-if="cardDetail.is_auth == 0">您已提交车队卡申请，待审核</p>
        <p v-else>您已提交车队卡申请已驳回，请重新申请</p>
      </template>
      <template v-if="showBtn">
        <Button plain hairline type="primary" class="immediately" @click="judgeOpen">马上开通</Button>
      </template>
      <template v-else>
        <Button v-if="cardDetail.is_auth == 1" plain hairline type="primary" class="immediately" @click="editTeamCard">重新提交</Button>
      </template>
    </div>
    <div class="management" v-else>
      <div class="information">
        <div class="information-top">
          <div class="information-head">
            <img src="@static/vip/touxiang.png" alt />
          </div>
          <div class="information-introduce">
            <p class="introduce-name">{{cardDetail.name}}</p>
          </div>
          <div class="editCard" @click="editTeamCard">
            <span class="iconfont iconshuxie"></span>
            <span>编辑</span>
          </div>
        </div>
        <div class="information-line"></div>
        <div class="infor-detils">
          <div>
            <div class="infor-detile-num">{{cardDetail.collect.balance}}</div>
            <div class="infor-detils-text">余额</div>
          </div>
          <div>
            <div class="infor-detile-num">{{cardDetail.collect.distribute_amount}}</div>
            <div class="infor-detils-text">分配金额</div>
          </div>
          <div>
            <div class="infor-detile-num">{{cardDetail.collect.consume_amount}}</div>
            <div class="infor-detils-text">消费</div>
          </div>
        </div>
      </div>
      <grid :gutter="20" :column-num="2" square class="grids">
        <grid-item
          v-for="(item, index) in operationList"
          :key="index"
          icon="photo-o"
          text="文字"
          @click="$router.push(item.link)"
        >
          <span class="iconfont" :class="item.icon" slot="icon"></span>
          <span slot="text" class="opera-name">{{item.name}}</span>
        </grid-item>
      </grid>
    </div>
  </div>
</template>

<script>
import creditshop from "@/api/creditshop";
import { Button, Grid, GridItem } from "vant";
export default {
  name: "carteamCard",
  components: {
    Button,
    Grid,
    GridItem
  },
  data() {
    return {
      operationList: [
        {
          name: "发卡",
          icon: "iconfaka",
          link: "/teamCardList"
        },
        {
          name: "司机列表",
          icon: "iconsiji",
          link: "/driverList"
        },
        {
          name: "储值",
          icon: "iconchuzhi",
          link: "/teamCardList?type=storedValue"
        },
        {
          name: "消费记录",
          icon: "iconxiaofeijilu",
          link: "/consumeRecord"
        }
      ],
      showOpenCard: false,
      cardDetail: "",
      showBtn: true //按钮显示
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    // 编辑信息
    editTeamCard() {
      this.$router.push({
        path: "/dredgePage",
        query: {
          type: "edit"
        }
      });
    },
    async judgeOpen() {
      try {
        const res = await creditshop.is_teamcard();
        if (res.code == 400) {
          this.$toast(res.msg);
        } else if (res.code == 200) {
          this.$router.push("/dredgePage");
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    async getDetail() {
      try {
        const res = await creditshop.team_carddetail();
        if (res.code == 200) {
          this.cardDetail = res.data;
          if (res.data.is_auth == 0) {
            this.$toast("您已提交车队卡申请，待审核");
            this.showBtn = false;
          } else if (res.data.is_auth == 1) {
            this.showBtn = false;
            this.$toast("您提交的申请已驳回，请重新申请");
          } else {
            this.showOpenCard = true;
            this.$set(
              this.operationList[1],
              "link",
              "/driverList?id=" + res.data.id
            );
            this.$set(
              this.operationList[3],
              "link",
              "/consumeRecord?id=" + res.data.id
            );
          }
        } else if (res.code == 400) {
          this.showOpenCard = false;
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.carteamCard {
  height: 100vh;
  background: #fff;

  .hint-img {
    height: 350px;
    text-align: center;
    display: flex;
    flex-direction: column;
    background: #fbfbfb;
    justify-content: center;
    align-items: center;

    .iconchedui {
      font-size: 100px;
      color: #999999;
    }
    p {
      margin-bottom: 15px;
    }
    .immediately {
      width: 50%;
    }
  }
  .management {
    height: 100%;
    padding: 10px;
  }
  .information {
    height: 162px;
    background: #fff;
    background: linear-gradient(45deg, #7a8ee3, #8cc1ff);
    border-radius: 10px;
    margin-bottom: 15px;

    .information-top {
      height: 90px;
      padding: 15px;
      display: flex;
      position: relative;

      .information-head {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        overflow: hidden;
      }

      img {
        width: 100%;
        height: 100%;
      }

      .information-introduce {
        margin-left: 15px;

        .introduce-name {
          font-size: 16px;
          font-weight: bold;
          color: #fff;
          display: flex;
          line-height: 60px;

          .changeShop {
            margin-left: 10px;
          }
        }

        .upgrade-hint {
          height: 24px;
          line-height: 24px;
          font-size: 10px;
          color: #fff;
        }

        .class-tags {
          display: flex;
          .class-img {
            width: 79px;
            height: 23px;
            // background: url("../../../public/static/images/vip/dengjibg.png");
            background-size: cover;
            padding-left: 28px;
            font-size: 10px;
            font-weight: bold;
            line-height: 23px;
            color: rgba(255, 255, 255, 1);
            margin-right: 5px;
          }

          .sign-tags {
            width: 52px;
            height: 23px;
            background: rgba(114, 175, 238, 1);
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;
            padding: 0 7px;

            img {
              width: 13px;
              height: 13px;
            }
          }
        }
      }
      .editCard {
        line-height: 60px;
        color: #fff;
        margin-left: 10px;
        width: 50px;
        display: flex;
        justify-content: space-evenly;
        position: absolute;
        right: 20px;
      }
    }

    .information-line {
      width: 325px;
      margin: 0 auto;
      height: 1px;
      background: #eeeeee;
    }

    .infor-detils {
      display: flex;
      > div {
        flex: 1;
        height: 72px;
        padding: 17px 0 21px;
        text-align: center;

        .infor-detile-num {
          height: 20px;
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          margin-bottom: 5px;
          span {
            font-size: 14px;
            font-weight: 600;
            color: #ff6354;
          }
        }

        .infor-detils-text {
          font-size: 12px;
          font-weight: 400;
          color: #fff;
        }
      }
    }
  }
  .grids {
    .opera-name {
      margin-top: 6px;
      font-size: 14px;
    }
    .iconfont {
      font-size: 24px;
    }
  }
}
</style>
